import React from "react";
import Header from "../../Headers/HeaderPrimary/HeaderPrimary";
import "../../Headers/HeaderPrimary/HeaderPrimary.scss";
import "../../Footer/Footer.scss";
import Footer from "../../Footer/Footer";
import ScrollToTop from "./ScrollToTop";

export default function LayoutPrimary({ children }) {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
