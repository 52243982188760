import React from "react";
import { Link } from "react-router-dom";
import "./Builders.scss";
import LayoutSecondary from "../Layouts/LayoutSecondary/LayoutSecondary";
import builderBannerRightImage from "../../assets/images/Builders/builderbannerright.png";
import builderProcessImage from "../../assets/images/Builders/process.png";
import mottoIcon1 from "../../assets/images/Builders/mottoicon1.svg";
import mottoIcon2 from "../../assets/images/Builders/mottoicon2.svg";
import mottoIcon3 from "../../assets/images/Builders/mottoicon3.svg";
import builderRightImage from "../../assets/images/Builders/builderright1.png";
import currentInvestmentImage1 from "../../assets/images/Development/currentinvestment1.png";
import currentInvestmentImage2 from "../../assets/images/Development/currentinvestment2.png";
import premiumAmmenitiesImage from "../../assets/images/Builders/premiumammenitiesimage.png";

const projects = [
  {
    id: 1,
    name: "Riverfront Development Project",
    image: currentInvestmentImage1,
    status: "Available",
  },
  {
    id: 2,
    name: "Beachside Luxury Villa",
    image: currentInvestmentImage2,
    status: "Available",
  },
  {
    id: 3,
    name: "Mountain Retreat",
    image: currentInvestmentImage2,
    status: "Coming Soon",
  },
  {
    id: 4,
    name: "Urban Skyline",
    image: currentInvestmentImage1,
    status: "Coming Soon",
  },
];
const Builders = () => {
  return (
    <LayoutSecondary>
      <div className="builder">
        <div className="builderbanner">
          <div className="row  align-items-center justify-content-center">
            <div className="col-7">
              <div className="builderbannerhead">
                WELCOME TO
                <br />
                <span>LUCKY DUCK BUILDERS</span> 
              </div>
              <div className="builderbannerdesc">
                Specializes in constructing high-end, luxury spec homes in prime
                waterfront locations across Southwest Florida, including Cape
                Coral, Naples, and Fort Myers. The company aims to create
                exquisite, move-in-ready homes that cater to discerning buyers
                looking for upscale living.
              </div>
              <Link to="/contact-us">
                <button type="submit" className="btn contactbtn mt-3">
                  Contact Us
                </button>
              </Link>
            </div>
            <div className="col-5 text-center">
              <div className="builderbannerimg">
                <img src={builderBannerRightImage} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="builderprocess">
          <div className="row align-items-center justify-content-center">
            <div className="col-6  text-center">
              <div className="builderprocessimg">
                <img src={builderProcessImage} alt="builderProcessImage" />
              </div>
            </div>
            <div className="col-6">
              <div className="builderprocesshead">
                OUR <span>PROCESS</span>
              </div>
              <div className="builderprocessdata">
                <ul>
                  <li>
                    <b className="builderprocessdatapoint">DEFINE</b>
                  </li>
                  <div className="builderprocessdatadesc">
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </div>
                  <li>
                    <b className="builderprocessdatapoint">DESIGN</b>
                  </li>
                  <div className="builderprocessdatadesc">
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </div>
                  <li>
                    <b className="builderprocessdatapoint">DELIVER</b>
                  </li>
                  <div className="builderprocessdatadesc">
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="buildermotto">
          <div className="buildermottohead">YOUR DREAM OUR ASSIGNMENT</div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon1} alt="mottoIcon1" />
                </div>
                <div className="buildermottocardhead">MARKET FOCUS</div>
                <div className="buildermottocarddesc">
                  Lucky Duck Builders targets affluent buyers and investors
                  looking for luxury waterfront properties. The company
                  leverages the appeal of Southwest Florida’s coastal lifestyle
                  to attract clients seeking both primary residences and
                  vacation homes.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon2} alt="mottoIcon2" />
                </div>
                <div className="buildermottocardhead">CUSTOMER EXPERIENCE</div>
                <div className="buildermottocarddesc">
                  The company provides a personalized experience for investors
                  and buyers, offering bespoke design options and dedicated
                  project management to ensure every detail meets the highest
                  standards.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon3} alt="mottoIcon3" />
                </div>
                <div className="buildermottocardhead">PERSONALIZED SERVICE</div>
                <div className="buildermottocarddesc">
                  The company provides a personalized ex perience for investors
                  and buyers, offering bespoke design options and dedicated
                  project management to ensure every detail meets the highest
                  standards.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="builderluxcon">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6">
              <div className="builderluxconhead">
                LUXURY <span>CONSTRUCTION</span>
              </div>
              <div className="builderluxcondesc">
                homes are built with the finest materials and craftsmanship.
                Features include custom cabinetry, high-end appliances, marble
                countertops, hardwood floors, and smart home technology.
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="builderluxconimg">
                <img src={builderRightImage} alt="builderimageright" />
              </div>
            </div>
          </div>
        </div>
        <div className="builderbuyeropp">
          <h2 className="builderbuyeropphead">
            CURRENT BUYER <span>OPPORTUNITIES</span>
          </h2>
          <div className="builderbuyeroppgrid">
            {projects.map((projects) => (
              <div key={projects.id} className="builderbuyeroppgridcard">
                <div className="builderbuyeroppgridcardimage">
                  <img src={projects.image} alt={projects.name} />
                </div>

                <div className="builderbuyeroppgridcardoverlay">
                  <span className="builderbuyeroppgridcardoverlayname">
                    {projects.name}
                  </span>
                  <Link
                    to={`/builders/property-list/property-details/${projects.id}`}
                    className="builderbuyeroppgridcardoverlaybutton"
                  >
                    View Project
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="builderbuyeroppviewall">
            <Link
              className="builderbuyeroppviewalllink"
              to="/builders/property-list"
            >
              View All Projects
              <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
        </div>
        <div className="builderpremiumammenities">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6 builderpremiumammenitiesdata">
              <div className="builderpremiumammenitiesdatahead">
                PREMIUM <span>AMMENITIES</span>
              </div>
              <div className="builderpremiumammenitiesdatadesc">
                We are more than a business; we are a family of companies that
                work together help you reach your goals as a real estate
                investor or owner.
                <br />
                <p className="pt-1">
                  We are family owned and operated specializing in finding,
                  developing and building unique property experiences.Developed
                  by our family for yours. Let’s travel this journey together
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="builderpremiumammenitiesimg">
                <img src={premiumAmmenitiesImage} alt="builderimageright" />
              </div>
            </div>
          </div>
        </div>
        <div className="builderbannerstatus">
          "LET'S BUILD OUR FUTURE TOGETHER"
        </div>
      </div>
    </LayoutSecondary>
  );
};

export default Builders;
