import React, { useState, useEffect } from "react";
import Layout from "../Layouts/LayoutPrimary/LayoutPrimary";
import aboutusbannerright from "../../assets/images/About/aboutusbannerright.png";
import aboutusstory from "../../assets/images/Careers/storyleft.png";
import "./Careers.scss";
import ApplyJobForm from "./ApplyJobForm";

const jobListings = [
  {
    id: 1,
    title: "Project Manager",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    id: 2,
    title: "Software Engineer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    id: 3,
    title: "Tech Lead",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

const Careers = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  // Function to open the modal with the selected Job's details
  const openModal = (jobId) => {
    setSelectedJob(jobId);
  };

  // Handle modal show behavior using useEffect
  useEffect(() => {
    if (selectedJob) {
      const modalElement = document.getElementById("applyjob");

      // Only show the modal if it exists and selectedMember is set
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show(); // Show the modal
      }
    }
  }, [selectedJob]); // This effect runs only when selectedMember changes

  // Function to close the modal
  const closeModal = () => {
    setSelectedJob(null); // Reset selected job
  };

  return (
    <Layout>
      <div>
        <div className="careerpage">
          <div className="careerpagebanner">
            Careers
            <div className="ms-auto">
              <img src={aboutusbannerright} alt="aboutusbannerright" />
            </div>
          </div>
        </div>
        <div className="careerpagestory">
          <div className="row align-items-center justify-content-center">
            <div className="col text-center">
              <div className="careerpagestoryimgright">
                <img src={aboutusstory} alt="aboutusstory" />
              </div>
            </div>
            <div className="col">
              <div className="careerpagestoryhead">
                 DISCOVER THE <span>OPPORTUNITY</span>
              </div>
              <div className="careerstorypagedesc">
                Lucky Duck Group LLC is a consortium of companies dedicated to
                the development, construction, and property management of luxury
                homes and multifamily apartments in Southwest Florida. The group
                operates in prime locations such as Naples, Fort Myers, Cape
                Coral, and Bonita Springs, offering a comprehensive range of
                services to meet the needs of high-end real estate investors and
                residents.
              </div>
            </div>
          </div>
        </div>
        <div className="careerpagetalent">
          <div className="careerpagetalenthead">
            JOIN OUR TALENT <span>COMMUNITY</span>
          </div>
          <div className="row">
            {jobListings.map((job) => (
              <div className="col-4" key={job.id}>
                <div className="careerpagetalentcard">
                  <div className="careerpagetalentcardhead">{job.title}</div>
                  <div className="careerpagetalentcarddata">
                    {job.description}
                  </div>
                  <div className="careerpagetalentcardbtn">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => openModal(job.id)}
                    >
                      APPLY
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {selectedJob && (
          <ApplyJobForm jobId={selectedJob} onClose={closeModal} />
        )}
      </div>
    </Layout>
  );
};

export default Careers;
