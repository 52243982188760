import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import "./PropertyDetails.scss";
import Layout from "../../Layouts/LayoutPrimary/LayoutPrimary";
import Image1 from "../../../assets/images/Home/tour.png";

const properties = [
  {
    id: 1,
    name: "Riverfront Development Project",
    images: [Image1, Image1, Image1, Image1],
  },
  {
    id: 2,
    name: "Beachside Luxury Villa",
    images: [Image1, Image1, Image1, Image1],
  },
  {
    id: 3,
    name: "Mountain Retreat",
    images: [Image1, Image1, Image1, Image1],
  },
  {
    id: 4,
    name: "Urban Skyline",
    images: [Image1, Image1, Image1, Image1],
  },
];

const PropertyDetails = () => {
  const { id } = useParams();
  const property = properties.find((prop) => prop.id === parseInt(id));
  const [selectedImage, setSelectedImage] = useState(null);

  if (!property) {
    return (
      <Layout>
        <div>
          <h1>Property not found</h1>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="propertydetails">
        {/* Banner Section */}
        <div
          className="propertydetailsbanner"
          style={{ backgroundImage: `url(${property.images[0]})` }}
        >
          <div className="propertydetailsbanneroverlay">
            <div className="propertydetailsbanneroverlaydetails">
              <div className="propertydetailsbanneroverlaydetailsdata">
                <span className="propertydetailsbanneroverlaydetailsdatalabel">
                  Location
                </span>
                <span className="propertydetailsbanneroverlaydetailsdatavalue">
                  Lorem Ipsum
                </span>
              </div>
              <div className="propertydetailsbanneroverlaydetailsdata">
                <span className="propertydetailsbanneroverlaydetailsdatalabel">
                  Project Size
                </span>
                <span className="propertydetailsbanneroverlaydetailsdatavalue">
                  160m²
                </span>
              </div>
              <div className="propertydetailsbanneroverlaydetailsdata">
                <span className="propertydetailsbanneroverlaydetailsdatalabel">
                  Project Date
                </span>
                <span className="propertydetailsbanneroverlaydetailsdatavalue">
                  August 2022
                </span>
              </div>
              <div className="propertydetailsbanneroverlaydetailsdatabutton">
                <button className="propertydetailsbanneroverlaydetailsdatabuttondownload">
                  <i className="fa-solid fa-download"></i> Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="propertydetailsbody">
          {/* Breadcrumb Section */}
          <div className="propertydetailsbodybreadcrumb">
            <Link to="/">Home</Link>
            <span className="propertydetailsbodybreadcrumbseparator"> / </span>
            <Link to="/development">LDG Development</Link>
            <span className="propertydetailsbodybreadcrumbseparator"> / </span>
            <Link to="/development/property-list">List</Link>
            <span className="propertydetailsbodybreadcrumbseparator"> / </span>
            <Link to={`/development/property-list/property-details/${id}`}>
              Property Details
            </Link>
          </div>

          {/* About Project Section */}
          <div className="propertydetailsbodyaboutproject">
            <h2 className="propertydetailsbodyaboutprojecthead">
              ABOUT PROJECT
            </h2>
            <p className="propertydetailsbodyaboutprojectdesc">
              LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED{" "}
              <br /> DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA{" "}
              <br /> ALIQUA. UT ENIM AD MINIM VENIAM, QUIS NOSTRUD EXERCITATION{" "}
              <br /> ULLAMCO.
            </p>
            {/* Back to List Link */}
            <div className="propertydetailsbodyaboutprojectbacktolist">
              <Link to="/development/property-list">Back To List</Link>
            </div>
            <div className="propertydetailsbodyaboutprojectheroimg">
              <img src={property.images[0]} alt="Property" />
            </div>
          </div>

          {/* Get more information section */}
          <div className="propertydetailsbodygetmoreinfo">
            <div className="propertydetailsbodygetmoreinfocontent">
              <span>Get More Information About This Project</span>
              <div className="propertydetailsbodygetmoreinfocontentlinkbox">
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </div>

          {/* Project Gallery */}
          <div className="propertydetailsbodygallery">
            <h2 className="propertydetailsbodygalleryhead">PROJECT GALLERY</h2>
            <div className="propertydetailsbodygallerygrid">
              {property.images.map((image, index) => (
                <div
                  key={index}
                  className="propertydetailsbodygallerygriditem"
                  onClick={() => setSelectedImage(image)}
                >
                  <img src={image} alt={`Property ${property.name}`} />
                  <button className="propertydetailsbodygallerygriditemviewbutton">View</button>
                </div>
              ))}
            </div>

            {/* Fullscreen Modal */}
            {selectedImage && (
              <div
                className="propertydetailsbodygalleryfullscreenmodal"
                onClick={() => setSelectedImage(null)}
              >
                <img src={selectedImage} alt="Fullscreen view" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PropertyDetails;
