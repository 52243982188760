import React, { useState } from "react";
import Layout from "../Layouts/LayoutPrimary/LayoutPrimary";
//import axios from "axios";
import Swal from "sweetalert2";
import "./Contact.scss";
import contactbannerright from "../../assets/images/Contact/contactbannerright.png";
import contactform from "../../assets/images/Contact/contactform.png";

const Contact = () => {
  // State management
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    type: "Investment Opportunities",
  });

  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message } = formData;

    // Validation
    if (!firstName || !lastName || !email || !phone || !message) {
      Swal.fire({
        title: "Error",
        text: "Please fill in all required fields.",
        icon: "error",
      });
      return;
    }

    setLoading(true);
    try {
      console.log("Form data:", formData);

      //Reset Form after submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        type: "Investment Opportunities",
      });

      Swal.fire({
        title: "Success",
        text: "Your form has been submitted!",
        icon: "success",
      });

      // API call
      // const response = await axios.post("/api/contact", {
      //   firstName,
      //   lastName,
      //   email,
      //   phone,
      //   type,
      //   message,
      // });

      // if (response.status === 200) {
      //   Swal.fire("Success", "Your form has been submitted!", "success");
      //   setFormData({
      //     firstName: "",
      //     lastName: "",
      //     email: "",
      //     phone: "",
      //     message: "",
      //     type: "Investment Opportunities",
      //   });
      // } else {
      //   throw new Error("Unexpected server response");
      // }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire(
        "Error",
        "Something went wrong. Please try again later.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="contactpage">
        <div className="contactpagebanner">
          <div className="row align-items-center">
            <div className="col-7">Contact Us</div>
            <div className="col-5 text-center">
              <img src={contactbannerright} alt="contactbannerright" />
            </div>
          </div>
        </div>
        <div className="p-5">
          <div className="container">
            <div className="contactpageform">
              <div className="contactpageformhead">
                LET'S GET <span>IN TOUCH</span>
              </div>
              <div className="row">
                <div className="col-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 mt-4">
                      <label className="form-label">
                        First Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Last Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Email Address <span>*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Phone Number <span>*</span>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        className="form-control"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Select Type</label>
                      <select
                        name="type"
                        className="form-select"
                        value={formData.type}
                        onChange={handleChange}
                      >
                        <option value="Investment Opportunities">
                          Investment Opportunities
                        </option>
                        <option value="Purchasing a Property">
                          Purchasing a Property
                        </option>
                        <option value="Construction Project">
                          Construction Project
                        </option>
                        <option value="Property Management">
                          Property Management
                        </option>
                        <option value="Enquiry">Enquiry</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Message</label>
                      <textarea
                        name="message"
                        className="form-control"
                        rows="3"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Get more information"}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-7">
                  <div className="contactpageformimg">
                    <img src={contactform} alt="contact form" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
