import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, forgotPassword } from "../../store/authSlice";
import loginlogo from "../../assets/images/SigninRegister/login.svg";
import "./SigninRegister.scss";

export const SigninRegister = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("Buyer");
  const [rememberMe, setRememberMe] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const dispatch = useDispatch();
  const { isLoading, error, message } = useSelector((state) => state.auth);

  useEffect(() => {
    const modalElement = document.getElementById("signinModal");
    const modal = new window.bootstrap.Modal(modalElement);

    if (!isLoading && message) {
      modal.hide(); // Close modal after successful submission
    }
  }, [isLoading, message]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password, userType, rememberMe }));
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPassword({ email }));
  };

  return (
    <div
      className="sign-in modal fade"
      id="signinModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isForgotPassword ? "Forgot Password" : "Register/Sign In"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <img src={loginlogo} alt="Login Logo" />
            </div>

            {/* User Type Selection (Radio Buttons) */}
            {!isForgotPassword && (
              <div className="mt-4">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="userType"
                    id="buyerRadio"
                    value="Buyer"
                    checked={userType === "Buyer"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="buyerRadio">
                    Buyer
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="userType"
                    id="investorRadio"
                    value="Investor"
                    checked={userType === "Investor"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="investorRadio">
                    Investor
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="userType"
                    id="renterRadio"
                    value="Renter"
                    checked={userType === "Renter"}
                    onChange={(e) => setUserType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="renterRadio">
                    Renter
                  </label>
                </div>
              </div>
            )}

            {isForgotPassword ? (
              <form onSubmit={handleForgotPasswordSubmit} className="mt-3">
                <div className="mb-3">
                  <label htmlFor="forgotEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="forgotEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={isLoading}
                >
                  {isLoading ? "Sending..." : "Send Reset Link"}
                </button>
                {message && (
                  <div className="alert alert-success mt-2">{message}</div>
                )}
                {error && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
                <button
                  type="button"
                  className="btn btn-link mt-3"
                  onClick={() => setIsForgotPassword(false)}
                >
                  Back to Login
                </button>
              </form>
            ) : (
              <form onSubmit={handleLoginSubmit} className="mt-3">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-link p-0"
                    onClick={() => setIsForgotPassword(true)}
                  >
                    Forgot my password
                  </button>
                  <div className="ms-auto">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="rememberMe" className="ms-2">
                      Remember Me
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4"
                  disabled={isLoading}
                >
                  {isLoading ? "Logging in..." : "Login"}
                </button>
                {error && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninRegister;
