import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios"; // Uncomment this once backend is ready

// Login User Thunk
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData, thunkAPI) => {
    try {
      console.log("User Details: ", userData); // Logs email, password, rememberMe, userType

      // Once API is ready, use axios to send data to backend
      // const response = await axios.post("/api/auth/login", userData);
      // const { token, user, userType } = response.data;

      // Store token based on rememberMe
      // if (userData.rememberMe) {
      //   localStorage.setItem("token", token);
      // } else {
      //   sessionStorage.setItem("token", token);
      // }

      // Simulated successful response for testing
      return {
        user: { name: "Test User", email: userData.email },
        token: "fake-jwt-token",
        userType: userData.userType,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Login failed");
    }
  }
);

// Forgot Password Thunk
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (emailData, thunkAPI) => {
    try {
      console.log("Forgot Password Request: ", emailData);

      // const response = await axios.post("/api/auth/forgot-password", emailData);
      // Simulated successful response
      return { message: "Password reset link sent to your email." };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to send reset link"
      );
    }
  }
);

// Auth Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userType: null, // Track user type here
    token: null,
    isLoading: false,
    error: null,
    message: null, // To track messages like "Forgot Password"
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.userType = null;
      state.token = null;
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     // Login User
  //     .addCase(loginUser.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //     })
  //     .addCase(loginUser.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.user = action.payload.user;
  //       state.userType = action.payload.userType;
  //       state.token = action.payload.token;
  //     })
  //     .addCase(loginUser.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload || "Login failed.";
  //     })

  //     // Forgot Password
  //     .addCase(forgotPassword.pending, (state) => {
  //       state.isLoading = true;
  //       state.error = null;
  //       state.message = null;
  //     })
  //     .addCase(forgotPassword.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.message = action.payload.message;
  //     })
  //     .addCase(forgotPassword.rejected, (state, action) => {
  //       state.isLoading = false;
  //       state.error = action.payload || "Failed to send reset link.";
  //     });
  // },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
