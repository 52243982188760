import React, { useState, useEffect } from "react";
import Layout from "../Layouts/LayoutPrimary/LayoutPrimary";
import "./About.scss";
import aboutUsBannerRight from "../../assets/images/About/aboutusbannerright.png";
import aboutUsStory from "../../assets/images/About/aboutusstory.png";
import team1 from "../../assets/images/About/team1.png";
import team2 from "../../assets/images/About/team2.png";
import team3 from "../../assets/images/About/team3.png";
import mailIcon from "../../assets/images/About/mailicon.png";
import contactIcon from "../../assets/images/About/contacticon.png";

// Array of objects
const teamMembers = [
  {
    id: 1,
    name: "Emily Gros Naccarato",
    position: "President, LDG Development",
    email: "test1@abc.com",
    image: team2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    id: 2,
    name: "David Naccarato",
    position: "President,LDG Building & Construction",
    email: "test2@abc.com",
    image: team3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    id: 3,
    name: "Parker Mercer",
    position: "Associate, LDG Development",
    email: "test3@abc.com",
    image: team1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

const About = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to open the modal with the selected member's details
  const openModal = (member) => {
    setSelectedMember(member); // Set selected member in state
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedMember(null); // Reset selected member
  };

  // Handle modal show behavior using useEffect
  useEffect(() => {
    if (selectedMember) {
      const modalElement = document.getElementById("teamcontact1");

      // Only show the modal if it exists and selectedMember is set
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show(); // Show the modal
      }
    }
  }, [selectedMember]); // This effect runs only when selectedMember changes

  return (
    <Layout>
      <div className="about-modal">
        <div className="aboutuspage">
          <div className="aboutuspagebanner">
            About Us
            <div className="ms-auto">
              <img src={aboutUsBannerRight} alt="About Us Banner" />
            </div>
          </div>
          <div className="aboutuspagestory">
            <div className="row align-items-center justify-content-center">
              <div className="col text-center">
                <div className="aboutuspagestoryimgright">
                  <img src={aboutUsStory} alt="Our Story" />
                </div>
              </div>
              <div className="col">
                <div className="aboutuspagestoryhead">
                  Our <span>Story</span>
                </div>
                <div className="aboutusstorypagedesc">
                  Lucky Duck Group LLC is a consortium of companies dedicated to
                  the development, construction, and property management of
                  luxury homes and multifamily apartments in Southwest Florida.
                  The group operates in prime locations such as Naples, Fort
                  Myers, Cape Coral, and Bonita Springs, offering a
                  comprehensive range of services to meet the needs of high-end
                  real estate investors and residents.
                </div>
              </div>
            </div>
          </div>

          <div className="aboutuspagemission">
            <div className="aboutuspagemissionhead">Mission Statement</div>
            <div className="aboutuspagemissiondesc">
              We are more than a business; we are a family of companies that
              work together help you reach your goals as a real estate investor
              or owner. We are family owned and operated specializing in
              finding, developing, and building unique property experiences.
            </div>
          </div>

          <div className="aboutuspagebuild">
            "Let's Build Our <span>Future Together"</span>
          </div>

          <div className="aboutuspageteam">
            <div className="aboutuspageteamhead">MEET THE TEAM</div>
            <div className="row">
              {teamMembers.map((member) => (
                <div className="col text-center" key={member.id}>
                  <div className="aboutuspageteaminfo">
                    <img src={member.image} alt={member.name} />
                    <div className="aboutuspageteamdetail">
                      <div className="row">
                        <div className="col-8">
                          <div className="aboutuspageteamdetailname">
                            {member.name}
                          </div>
                          <div className="aboutuspageteamdetailpost">
                            {member.position}
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="aboutuspageteamdetailicons">
                            <img
                              src={contactIcon}
                              alt="Contact"
                              onClick={() => openModal(member)}
                              className="pointer"
                            />
                            <a
                              href={`mailto:${member.email}`}
                              aria-label={`Send email to ${member.name}`}
                            >
                              <img
                                src={mailIcon}
                                alt="Mail"
                                className="pointer"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {selectedMember && (
            <div
              className="modal fade"
              id="teamcontact1"
              tabIndex="-1"
              aria-labelledby="teamcontact1Label"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{selectedMember.name}</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closeModal}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <div className="aboutuspageteammember">
                          <img
                            className="aboutuspageteammember"
                            src={selectedMember.image}
                            alt={selectedMember.name}
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="aboutuspageteammemberdesc">
                          {selectedMember.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default About;
