import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ProjectList.scss";
import Layout from "../../Layouts/LayoutPrimary/LayoutPrimary";
import Image1 from "../../../assets/images/Development/currentinvestment1.png";
import aboutUsBannerRight from "../../../assets/images/About/aboutusbannerright.png";

// Sample data for property cards with `type` field
const sampleProperties = [
  {
    id: 1,
    title: "Property 1",
    image: Image1,
    type: "Commercial",
    price: "$3000",
    location: "Kansas City",
  },
  {
    id: 2,
    title: "Property 2",
    image: Image1,
    type: "Retail",
    price: "$2500",
    location: "Austin",
  },
  {
    id: 3,
    title: "Property 3",
    image: Image1,
    type: "Office",
    price: "$3200",
    location: "Seattle",
  },
  {
    id: 4,
    title: "Property 4",
    image: Image1,
    type: "Commercial",
    price: "$2800",
    location: "Boston",
  },
  {
    id: 5,
    title: "Property 5",
    image: Image1,
    type: "Retail",
    price: "$2700",
    location: "New York",
  },
  {
    id: 6,
    title: "Property 6",
    image: Image1,
    type: "Office",
    price: "$3100",
    location: "Chicago",
  },
  {
    id: 7,
    title: "Property 7",
    image: Image1,
    type: "Commercial",
    price: "$3100",
    location: "San Diego",
  },
];

const ProjectList = () => {
  //const [selectedProperties, setSelectedProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("All"); // State for selected filter

  const propertiesPerPage = 6;

  // Filter properties based on the selected filter
  const filteredProperties =
    selectedFilter === "All"
      ? sampleProperties
      : sampleProperties.filter((property) => property.type === selectedFilter);

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);
  const displayedProperties = filteredProperties.slice(
    (currentPage - 1) * propertiesPerPage,
    currentPage * propertiesPerPage
  );

  // const handleAddProperty = (propertyId) => {
  //   setSelectedProperties((prev) =>
  //     prev.includes(propertyId)
  //       ? prev.filter((id) => id !== propertyId)
  //       : [...prev, propertyId]
  //   );
  // };

  return (
    <Layout>
      <div className="projectlist">
        <div className="propertylistbanner">
          PROPERTIES
          <div className="ms-auto">
            <img src={aboutUsBannerRight} alt="About Us Banner" />
          </div>
        </div>

        <div className="propertylisthead">PROPERTY INSIGHTS</div>

        {/* Property Filters */}
        <div className="propertylistfilter">
          <button onClick={() => setSelectedFilter("All")}>All</button>
          <button onClick={() => setSelectedFilter("Commercial")}>
            Commercial
          </button>
          <button onClick={() => setSelectedFilter("Retail")}>Retail</button>
          <button onClick={() => setSelectedFilter("Office")}>Office</button>
        </div>

        {/* Back to List Link */}
        <div className="propertylistbacktodevelopment">
          <Link to="/builders">Back To LDG Builders</Link>
        </div>

        {/* Property Cards */}
        <div className="propertylistgrid">
          {displayedProperties.map((property) => (
            <div className="propertylistgridcard" key={property.id}>
              <div className="propertylistgridcardimage">
              <img
                src={property.image}
                alt={property.title}
              />
              </div>
              <div className="propertylistgridcardoverlay">
                <div className="propertylistgridcardoverlayname">
                  {property.title}
                </div>
                <Link
                  to={`/builders/property-list/property-details/${property.id}`}
                  className="propertylistgridcardoverlayviewmorebtn"
                >
                  View More
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="propertylistpagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>

        {/* Compare Properties */}
        {/* <div className="compare-properties">
          <div className="careerpagetalent">
            <div className="careerpagetalenthead">
              COMPARE PROPERTIES IN LOREM
            </div>

            <div className="compare-cards-container">
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
            </div>

            <div className="text-center">
              <button className="compare-button">Compare Properties</button>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default ProjectList;
