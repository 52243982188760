import React from "react";
import { Link, useLocation } from "react-router-dom";
import logoGroup from "../../../assets/images/Headers/logogroup.svg";
import SigninRegister from "../../SigninRegister/SigninRegister";

const HeaderSecondary = () => {
  const location = useLocation();
  return (
    <div>
      <nav className="navbar px-4 navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={logoGroup}
              alt=""
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse flex-grow-0 navbar-collapse" id="navbarNav">
            <ul className="navbar-nav gap-5">
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/" ? "nav-link active" : "nav-link"
                  }
                  aria-current={location.pathname === "/" ? "page" : undefined}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/about-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/about-us" ? "page" : undefined
                  }
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/careers"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/careers" ? "page" : undefined
                  }
                  to="/careers"
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/contact-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/contact-us" ? "page" : undefined
                  }
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
              <button
                type="button"
                className="loginbtn btn btn-light"
                data-bs-toggle="modal"
                data-bs-target="#signinModal"
              >
                Login
              </button>
              <SigninRegister />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderSecondary;
