import React from "react";
import LayoutSecondary from "../Layouts/LayoutSecondary/LayoutSecondary";

const PropertyManagement = () => {
  return (
    <LayoutSecondary>
      <div>
        <h1>LDG Property Management</h1>
      </div>
    </LayoutSecondary>
  );
};

export default PropertyManagement;
