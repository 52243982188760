import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../assets/images/Development/completedProject1.png";
import Image2 from "../../assets/images/Development/completedProject2.png";
import Image3 from "../../assets/images/Development/completedProject3.png";
import Image4 from "../../assets/images/Development/completedProjectpic1.png";
import Image5 from "../../assets/images/Development/completedProjectpic2.png";

//Array of Property Details
const properties = [
  {
    heroImage: Image1,
    carouselImages: [Image4, Image5],
    details: {
      title: "Property 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
      price: "$3000",
      area: "Kansas City",
      size: "1300 Sqft",
      bedrooms: "5",
      rooms: "3",
      structure: "Wood",
    },
  },
  {
    heroImage: Image2,
    carouselImages: [Image4, Image5],
    details: {
      title: "Property 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      price: "$3200",
      area: "New York",
      size: "1400 Sqft",
      bedrooms: "4",
      rooms: "2",
      structure: "Brick",
    },
  },
  {
    heroImage: Image3,
    carouselImages: [Image4, Image5],
    details: {
      title: "Property 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      price: "$2900",
      area: "California",
      size: "1200 Sqft",
      bedrooms: "3",
      rooms: "2",
      structure: "Concrete",
    },
  },
];

const carouselSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const CompletedProjects = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  return (
    <div className="developmentpagecompletedprojects">
      <h2 className="developmentpagecompletedprojectshead">
        COMPLETED <span>PROJECTS</span>
      </h2>
      <div className="row">
        {properties.map((property, index) => (
          <div key={index} className="col-4">
            {/* Hero Image */}
            <div
              className="developmentpagecompletedprojectshero"
            >
              <img src={property.heroImage} alt={`Hero ${index + 1}`} />
            </div>

            {/* Carousel */}
            <div className="developmentpagecompletedprojectscarousel">
              <Slider {...carouselSettings}>
                {property.carouselImages.map((image, imgIndex) => (
                  <div key={imgIndex} onClick={() => setSelectedImage(image)}>
                    <img src={image} alt="" />
                  </div>
                ))}
              </Slider>
              {/* Fullscreen Modal */}
            {selectedImage && (
              <div
                className="developmentpagecompletedprojectscarouselfullscreenmodal"
                onClick={() => setSelectedImage(null)}
              >
                <img src={selectedImage} alt="Fullscreen view" />
              </div>
            )}
            </div>

            {/* Property Details */}
            
            <div className="developmentpagecompletedprojectsdetails">
              <div className="row developmentpagecompletedprojectsdetailstitle">{property.details.title}</div>
              <div className="row developmentpagecompletedprojectsdetailsdesc">{property.details.description}</div>
              <div className="row">
              <div className="col developmentpagecompletedprojectsdetailsdescdata">
                <div className="row">Price: {property.details.price}</div>
                <div className="row">Area: {property.details.area}</div>
              </div>
              <div className="col developmentpagecompletedprojectsdetailsdescdata">
                <div className="row">Size: {property.details.size}</div>
                <div className="row">Bedrooms: {property.details.bedrooms}</div>
              </div>
              <div className="col developmentpagecompletedprojectsdetailsdescdata">
                <div className="row">Rooms: {property.details.rooms}</div>
                <div className="row">Structure Type: {property.details.structure}</div>
              </div>
              </div>
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedProjects;
