import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../assets/images/Development/beforeimage.png";
import Image2 from "../../assets/images/Development/afterimage.png";

const carouselItems = [
  {
    beforeImage: Image1,
    afterImage: Image2,
  },
  {
    beforeImage: Image2,
    afterImage: Image1,
  },
];

const CompletedInvestorProjects = () => {
  const carouselSettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="developmentpagecompletedinvestorprojectscarousel">
      <div className="developmentpagecompletedinvestorprojectscarouselhead">
        COMPLETED INVESTOR PROJECTS
      </div>
      <Slider {...carouselSettings}>
        {carouselItems.map((item, index) => (
          <div key={index}>
            <div className="developmentpagecompletedinvestorprojectscarouselslide">
              <div>
                <h3 className="developmentpagecompletedinvestorprojectscarouselslideimage">
                  Before
                </h3>
                <img src={item.beforeImage} alt="Before" />
              </div>
              <div>
                <h3 className="developmentpagecompletedinvestorprojectscarouselslideimage">
                  After
                </h3>
                <img src={item.afterImage} alt="After" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CompletedInvestorProjects;
