import React from "react";
import Header from "../../Headers/HeaderSecondary/HeaderSecondary";
import "../../Headers/HeaderSecondary/HeaderSecondary.scss";
import "../../Footer/Footer.scss";
import Footer from "../../Footer/Footer";
import ScrollToTop from "../LayoutPrimary/ScrollToTop";

const LayoutSecondary = (props) => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default LayoutSecondary;
