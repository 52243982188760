import React from "react";
import { Link } from "react-router-dom";
import "./Development.scss";
import LayoutSecondary from "../Layouts/LayoutSecondary/LayoutSecondary";
import firstBannerImage from "../../assets/images/Development/developmentbannerone.png";
import secondBannerImage from "../../assets/images/Development/financialstratergy.png";
import currentInvestmentImage1 from "../../assets/images/Development/currentinvestment1.png";
import currentInvestmentImage2 from "../../assets/images/Development/currentinvestment2.png";
import marketFocusImage from "../../assets/images/Development/marketfocus.png";
import exclusivelocationsImage from "../../assets/images/Development/exclusivelocations.png";
import personalizedserviceImage from "../../assets/images/Development/personalizedservice.png";
import CompletedProjects from "./CompletedProjects";
import CompletedInvestorProjects from "./CompletedInvestorProjects";

const properties = [
  {
    id: 1,
    name: "Riverfront Development Project",
    image: currentInvestmentImage1,
    status: "Available",
  },
  {
    id: 2,
    name: "Beachside Luxury Villa",
    image: currentInvestmentImage2,
    status: "Available",
  },
  {
    id: 3,
    name: "Mountain Retreat",
    image: currentInvestmentImage2,
    status: "Coming Soon",
  },
  {
    id: 4,
    name: "Urban Skyline",
    image: currentInvestmentImage1,
    status: "Coming Soon",
  },
];

const Development = () => {
  return (
    <LayoutSecondary>
      <div className="developmentpage">
        <div className="developmentpagebanner">
          <div className="row">
            <div className="col-7">
              <div className="developmentpagebannerfirsthead">
                WELCOME TO <br />
                <span>LDG DEVELOPMENT</span>
              </div>
              <div className="developmentpagebannerfirstdesc">
                We Are A Leading Real Estate Investment Company Specializing In
                The Development Of Unique Properties In Southwest Florida. We
                Search To Acquire Properties That We Can Transform Into High
                Value Opportunities That Will Maximize Returns For Our
                Investors. Our Focus In On Developing Market Luxury Waterfront
                Properties In Prime Locations
              </div>
              <div className="btn mt-3 developmentpagebannerfirstlinkbox">
                <Link to="/contact-us">
                  <div className="developmentpagebannerfirstlinkboxview">
                    Contact Us{" "}
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-5 developmentpagebannerfirstimage">
              <img src={firstBannerImage} alt="" />
            </div>
          </div>
        </div>

        <div className="developmentpageinvestmentstrategy">
          <div className="row align-items-center justify-content-center">
            <div className="col-6">
              <div className="developmentpageinvestmentstrategyhead">
                INVESTMENT <span>STRATEGY</span>
              </div>
              <ul>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                  EQUITY FINANCING
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  The Company Raises Capital Through Equity Investments,
                  Allowing Investors To Share In The Profits From The Sale Of
                  Luxury Hores.
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    COST MANAGEMENT
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  Efficient Cost Management And A Strong Business Pian Are
                  Crucial To Maximising Returns On Spec Home Projects
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    RISK MITIGATION
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  By Focusing High-Demand Areas & Maintaining A Diverse
                  Portfolio Of Properties LDG Development LIc Mitigates Risk
                  Associated With Speculative Bulldings.
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    HIGH ROI
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  The Company Airs To Delver High Returns On Investment By
                  Developing Properties in Prime Location & Ensuring Top-Quality
                  Contruction And Amenties
                </div>
              </ul>
            </div>
            <div className="col-6 text-center">
              <div className="developmentpageinvestmentstrategybanner">
                <img src={secondBannerImage} alt="" />
              </div>
            </div>
          </div>
        </div>
        
          <CompletedProjects />
        
        <div className="developmentpageassignmentouter">
          <div className="developmentpageassignment">
            <div className="developmentpageassignmenthead">
              YOUR DREAM OUR ASSIGNMENT
            </div>
            <div className="row">
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img src={marketFocusImage} alt="" />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    MARKET FOCUS
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    Lucky Duck Builders targets affluent buyers and investors
                    looking for luxury waterfront properties. The company
                    leverages the appeal of Southwest Florida’s coastal
                    lifestyle to attract clients seeking both primary residences
                    and vacation homes.
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img src={exclusivelocationsImage} alt="" />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    EXCLUSIVE LOCATIONS
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    The company focuses on acquiring and developing properties
                    in the most sought-after waterfront locations, offering
                    stunning views and direct access to water activities.
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img src={personalizedserviceImage} alt="" />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    PERSONALISED SERVICE
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    The company provides a personalized experience for investors
                    and buyers, offering bespoke design options and dedicated
                    project management to ensure every detail meets the highest
                    standards.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="developmentpagecurrentinvestment">
          <h2 className="developmentpagecurrentinvestmenthead">
            CURRENT INVESTMENT <span>OPPORTUNITIES</span>
          </h2>
          <div className="developmentpagecurrentinvestmentgrid">
            {properties.map((property) => (
              <div
                key={property.id}
                className="developmentpagecurrentinvestmentgridcard"
              >
                <div className="developmentpagecurrentinvestmentgridcardimage">
                <img
                  src={property.image}
                  alt={property.name}
                  
                />
                </div>
                
                <div className="developmentpagecurrentinvestmentgridcardoverlay">
                  <span className="developmentpagecurrentinvestmentgridcardoverlayname">
                    {property.name}
                  </span>
                  <Link
                    to={`/development/property-list/property-details/${property.id}`}
                    className="developmentpagecurrentinvestmentgridcardoverlaybutton"
                  >
                    View Project
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="developmentpagecurrentinvestmentviewall">
            <Link
              className="developmentpagecurrentinvestmentviewalllink"
              to="/development/property-list"
            >
              View All Projects
              <i className="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
        </div>
        <div className="developmentpageinvestorbanner">
          <div className="developmentpageinvestorbannersection">
            <div className="developmentpageinvestorbannersectionhead">
              INVESTOR RELATIONS
            </div>
            <div className="developmentpageinvestorbannersectiondesc">
              While the primary focus is on equity investments, LDG Development
              LLC maintains transparent and professional communication with
              investors, providing regular updates on project progress and
              financial performance
            </div>
          </div>
          <div className="developmentpageinvestorbannerbuild">
            "LET'S BUILD OUR <span>FUTURE TOGETHER"</span>
          </div>
        </div>

        <CompletedInvestorProjects />
      </div>
    </LayoutSecondary>
  );
};

export default Development;
