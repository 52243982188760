import React, { useState } from "react";
import Swal from "sweetalert2";
//import axios from "axios";

const ApplyJobForm = ({ jobId, onClose }) => {
  const [formData, setFormData] = useState({
    resume: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
    location: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      resume: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // Handle form submission logic here

    const formDataToSend = new FormData();
    formDataToSend.append("resume", formData.resume);
    formDataToSend.append("firstName", formData.firstName);
    formDataToSend.append("lastName", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("jobTitle", formData.jobTitle);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("jobId", jobId);

    try {
      console.log("Form submitted:", formData);

      Swal.fire("Success", "Your application has been submitted!", "success");

      // Close the modal after submission
      onClose();

      // const response = await axios.post("", formDataToSend, {
      //   headers: { "Content-Type": "multipart/form-data" },
      // });

      // if (response.status === 200) {
      //   Swal.fire("Success", "Your application has been submitted!", "success");
      //   onClose(); // Close the popup after successful submission
      // }
    } catch (error) {
      Swal.fire(
        "Error",
        "Failed to submit your application. Try again.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="applyjob"
      aria-labelledby="applyjob1Label"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="applyjob1Label">
              JOIN OUR TALENT COMMUNITY
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <form onSubmit={handleSubmit}>
                  <label className="form-label">
                    Upload Resume <span>*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                      required
                    />
                    <label className="input-group-text">Upload</label>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      First Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Last Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Email<span>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Phone<span>*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Job Title<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="jobTitle"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="form-label">
                      Location<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="location"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary applybtn"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Apply"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyJobForm;
