import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";
import devImage from "../../assets/images/Home/dev.svg";
import buildImage from "../../assets/images/Home/build.svg";
import pmImage from "../../assets/images/Home/PM.svg";
import secondBannerImage from "../../assets/images/Home/secondbanner.png";
import homeIcon1 from "../../assets/images/Home/homeicon1.svg";
import homeIcon2 from "../../assets/images/Home/homeicon2.svg";
import homeIcon3 from "../../assets/images/Home/homeicon3.svg";
import tourImage from "../../assets/images/Home/tour.png";
import Layout from "../Layouts/LayoutPrimary/LayoutPrimary";

export default function Home() {
  return (
    <Layout>
      <div>
        <div className="homepage">
          <div className="homepagebanner">
            <div className="row">
              <div className="col-7">
                <div className="homepagebannerfirsthead">
                  WELCOME TO <br />
                  <span>LUCKY DUCK GROUP</span>
                </div>
                <div className="homepagebannerfirstdesc">
                  We are more than a business; we are a family of companies that
                  work together help you reach your goals as a real estate
                  investor or owner.
                </div>
                <div className="homepagebannerfirstdesc">
                  We are family owned and operated specializing in finding,
                  developing and building unique property experiences.Developed
                  by our family for yours.
                </div>
                <div className="homepagebannerfirststate">
                  "Let's Build Our Future Together"
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="homepagebannerfirstlinkbox">
                      <Link to="/development">
                        <img src={devImage} alt="" />
                        <div className="homepagebannerfirstlinkboxview">
                          View More{" "}
                          <i className="fa-solid fa-circle-chevron-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="homepagebannerfirstlinkbox">
                      <Link to="/builders">
                        <img src={buildImage} alt="" />
                        <div className="homepagebannerfirstlinkboxview">
                          View More{" "}
                          <i className="fa-solid fa-circle-chevron-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <div className="homepagebannerfirstlinkbox">
                      <Link to="/property-management">
                        <img src={pmImage} alt="" />
                        <div className="homepagebannerfirstlinkboxview">
                          View More{" "}
                          <i className="fa-solid fa-circle-chevron-right"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 d-flex align-items-center justify-content-center">
                <div className="homepagebannersecond">
                  <img src={secondBannerImage} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="homepageintro">
            <div className="homepageintrohead">
              OUR <span>EXPERTISE</span>
            </div>
            <div className="row mt-4">
              <div className="col">
                <div className="homepageintrocard">
                  <div className="homepageintrocardicon">
                    <img src={homeIcon1} alt="" />
                  </div>
                  <div className="homepageintrocardhead">
                    EQUITY INVESTMENT OPPORTUNITIES
                  </div>
                  <div className="homepageintrocarddesc">
                    LDG Development LLC offers attractive equity investment
                    opportunities in luxury real estate projects. Investors can
                    participate in high-return ventures with a focus on premium
                    properties.
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="homepageintrocard">
                  <div className="homepageintrocardicon">
                    <img src={homeIcon2} alt="" />
                  </div>
                  <div className="homepageintrocardhead">
                    STRATEGIC LAND ACQUISITION
                  </div>
                  <div className="homepageintrocarddesc">
                    The company identifies and acquires prime waterfront
                    properties with strong appreciation potential, ensuring
                    high-value investments.
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="homepageintrocard">
                  <div className="homepageintrocardicon">
                    <img src={homeIcon3} alt="" />
                  </div>
                  <div className="homepageintrocardhead">
                    LUXURY CONSTRUCTION
                  </div>
                  <div className="homepageintrocarddesc">
                    Homes are built with the finest materials and craftsmanship,
                    featuring custom cabinetry, high-end appliances, marble
                    countertops, hardwood floors, and smart home technology.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="homepagestatus">
            "Let's Build Our Future Together"
          </div>

          <div className="homepagetour">
            <div className="row align-items-center justify-content-center">
              <div className="col ">
                <div className="homepagetourhead">
                  IN YOUR <span>CORNER</span>
                </div>
                <div className="homepagetourdesc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </div>
              </div>
              <div className="col text-center">
                <div className="homepagetourvideo">
                  <img src={tourImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
